import messageApi from './message-api';
import {dom} from 'core-utils';

var __ = {},
	exports = {__};

__.TOP_OFFSET = 70;

__.registerEvents = function () {
	messageApi.registerForWindowEvent('scrollTo', __.handleScrollToMessage);
	messageApi.registerForWindowEvent('scrollTop', __.handleScrollTopMessage);
};

// scroll page to a given position
__.handleScrollToMessage = function (payLoad_) {
	var xPos = parseInt(payLoad_.x, 10),
		yPos = parseInt(payLoad_.y, 10);

	xPos = isNaN(xPos) ? 0 : xPos;
	yPos = isNaN(yPos) ? 0 : yPos;
	window.scrollTo(xPos, yPos);
};

// scroll page to fit the top of the iFrame
__.handleScrollTopMessage = function (payLoad_) {
	var yPos, yOffset = __.TOP_OFFSET, frameRect, frameTop, frame = messageApi.getFrameOriginByUrl(payLoad_.origin);

	if (frame) {
		frameRect = frame.getBoundingClientRect();
		frameTop = frameRect.top;

		if (frameTop < 0 && window.pageYOffset > 0) {
			yOffset = (!!payLoad_.offset) ? parseInt(payLoad_.offset, 10) : yOffset;
			yPos = Math.max(window.pageYOffset + frameTop - yOffset, 0);
			window.scrollTo(0, yPos);
		}
	}
};

/**
 * getOffsetOfElement - necessary function for calculation of scroll position, because offsetTop of relative positioned elements return 0
 * @param {HTMLElement} element_ - element
 * @returns {Object} - offset
 */
__.getOffsetOfElement = function (element_) {
	const rect = element_.getBoundingClientRect();
	const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

	return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
};

/**
 * @param {number} scrollPositionY_ - scroll position
 * @returns {boolean} - actual scroll position is at given position
 */
__.isPageScrollYAtPosition = function (scrollPositionY_) {
	return (window.pageYOffset === scrollPositionY_);
};

/**
 * @returns {number} headerHeight - height of header
 */
__.calculateHeaderOffset = function () {
	let headerOffset = 0;
	const headerElement = document.querySelector('.axs-j-header-wrapper');

	if (headerElement) {
		headerOffset = headerElement.clientHeight;
		headerOffset = headerOffset + headerElement.offsetTop;
	}

	return headerOffset;
};

/**
 * @param {HTMLElement} destinationElement_ - element where to scroll to
 * @param {Object} options_ - Options
 * @param {number} options_.offset - offset for preventing scroll position under fixed elements
 * @param {number} options_.duration - scroll animation duration in ms
 * @param {string} options_.easing - easing name (one of https://git.intern.dasburo.com/audi-nemo-fe/core-utils/blob/master/js/utils/easing.js )
 */
exports.scrollToElement = function (destinationElement_, options_) {
	const {
		offset: offset_ = 0,
		duration: duration_ = 0,
		easing: easing_ = 'linear'
	} = options_;

	const offset = offset_ - __.calculateHeaderOffset();
	const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
	const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
	let destinationOffset = __.getOffsetOfElement(destinationElement_).top + offset;

	if (destinationOffset < 0) {
		destinationOffset = 0;
	}

	let destinationOffsetToScroll = destinationOffset;

	if ((documentHeight - destinationOffset) < windowHeight) {
		destinationOffsetToScroll = documentHeight - windowHeight;
	}

	dom.scrollTo(Math.round(destinationOffsetToScroll), {
		duration: duration_,
		easing: easing_
	});
};

// basic initialization promise
exports.initialize = function (eventBus_) {
	return new Promise(resolve => {
		__.eventBus = eventBus_;
		__.registerEvents();
		resolve('msg/scroll.js initialized.');
	});
};

export {exports as scroll};
