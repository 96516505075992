export default class JScrollReveal {
	static get ATTRIBUTE_ITEM() {
		return 'data-scroll-reveal';
	}

	/**
	 * @param {Object}options_ - options
	 * @property {HTMLElement} options_.container - root of items to observe
	 * @property {string} options_.itemClass - class of item(s) to be observed
	 * @property {number} options_.percentage - percentage how much an item must be in viewport to be shown
	 */
	constructor(options_) {
		this.container = options_.container;
		this.itemClass = options_.itemClass;
		this.ratio = options_.percentage ? options_.percentage / 100 : .25;

		this._initialize();
	}

	_initialize() {
		const hiddenItems = this.container.querySelectorAll(`.${this.itemClass}[${JScrollReveal.ATTRIBUTE_ITEM}="hidden"]`);
		const numberHiddenItems = hiddenItems ? hiddenItems.length : 0;

		this.handleEntriesVisibility = this._handleEntriesVisibility.bind(this);

		if (numberHiddenItems > 0) {
			this._initObserverForItems(hiddenItems);
		}
	}

	/**
	 * @param {Array<HTMLElement>} items_ - items to be observed
	 */
	_initObserverForItems(items_) {
		const options = {
			threshold: this.ratio
		};

		this.observer = new IntersectionObserver(this.handleEntriesVisibility, options);

		[...items_].forEach(item_ => {
			this.observer.observe(item_);
		});
	}

	destroy() {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	/**
	 * @param {Array<HTMLElement>} entries_ - observer entries
	 */
	_handleEntriesVisibility(entries_) {
		entries_.forEach(entry_ => {
			if (entry_.intersectionRatio >= this.ratio) {
				this._showItem(entry_.target);
				this.observer.unobserve(entry_.target);
			}
		});
	}

	/**
	 * @param {HTMLElement} item_ - item to show
	 */
	_showItem(item_) {
		item_.setAttribute(JScrollReveal.ATTRIBUTE_ITEM, 'visible');
	}
}

export {JScrollReveal};
