import {dom} from 'core-utils';
import {signal} from 'application-bundle';

var __ = {},
	api = {__},
	options = {
		selectorSliderLeft: '.axs-at-slider-arrow-left',
		selectorSliderRight: '.axs-at-slider-arrow-right',
		selectorInputs : '.axs-at-slider-input',
		selectorLabels : '.axs-at-slider-input-label',
		autoAnimation : false,
		animationTime : 10000,
		pauseAnimation : false,
		alwaysShowArrows : false
	}
;

/**
 * Private
 * @var {Array} registered - register selectors, no duplicate classes
 * @var {Array} registeredItems - register all items in the DOM
 */
__.registered = [];
__.registeredItems = [];

__.gotoNextSlide = function (registeredItem_) {
	var slider_ = dom.getElementsArray(registeredItem_.options.selectorInputs, registeredItem_.item),
		activeSlide_;

	activeSlide_ = __.getActiveSlide(slider_);

	if (slider_[activeSlide_ + 1]) {
		__.checkItemAndTriggerEvent(activeSlide_ + 1, slider_);
	}
	else {
		if (slider_[0]) {
			__.checkItemAndTriggerEvent(0, slider_);
		}
	}

	__.setSelectedLabels();
	__.setActiveSlide();
};

__.createCustomInputChangeEvent = function () {
	return new CustomEvent(
		'input:changed',
		{
			detail: {
				message: 'input field changed'
			},
			bubbles: true,
			cancelable: true
		}
	);
};

__.checkItemAndTriggerEvent = function(index_, slider_) {
	if (slider_[index_]){
		slider_[index_].checked = true;
		slider_[index_].dispatchEvent(__.createCustomInputChangeEvent());
	}
};


__.arrowNextHandler = function (target_, registeredModule_) {
	var targetItem_ = target_,
		closest_ = dom.closest(targetItem_, registeredModule_.selector),
		slider_ = dom.getElementsArray(registeredModule_.options.selectorInputs, closest_),
		activeSlide_,
		newIndex;

	activeSlide_ = __.getActiveSlide(slider_);
	newIndex = activeSlide_+1;

	if (newIndex >= slider_.length){
		newIndex=0;
	}

	if (slider_[newIndex]){
		slider_[newIndex].checked = true;
	}

	__.setSelectedLabels();
	__.setActiveSlide();
	__.startAutoAnimation();
};

__.arrowPreviousHandler = function (target_, registeredModule_) {
	var targetItem_ = target_,
		closest_ = dom.closest(targetItem_, registeredModule_.selector),
		slider_ = dom.getElementsArray(registeredModule_.options.selectorInputs, closest_),
		activeSlide_,
		newIndex;

	activeSlide_ = __.getActiveSlide(slider_);

	newIndex=activeSlide_-1;

	if (newIndex<0){
		newIndex=slider_.length-1;
	}

	if (slider_[newIndex]){
		slider_[newIndex].checked = true;
	}

	__.setSelectedLabels();
	__.setActiveSlide();
	__.startAutoAnimation();
};

__.onChange = function () {
	__.setSelectedLabels();
	__.setActiveSlide();
	__.startAutoAnimation();
};

__.onNextClick = function (registeredModule_) {
	return function (e, element) {
		e.preventDefault();
		__.arrowNextHandler(element, registeredModule_);
	};
};

__.onPrevClick = function (registeredModule_) {
	return function (e, element) {
		e.preventDefault();
		__.arrowPreviousHandler(element, registeredModule_);
	};
};

__.onMouseOver = function () {
	return function (e, element) {
		e.preventDefault();
		clearTimeout(__.getRegisteredItem(element).timeout);
		element.setAttribute('mouse-over', true);
	};
};

__.onMouseOut = function () {
	return function (e, element) {
		e.preventDefault();
		element.removeAttribute('mouse-over');
		__.setTimeoutForAnimation(__.getRegisteredItem(element));
	};
};

__.loopAutoAnimation = function (registeredModule_) {
	__.gotoNextSlide(registeredModule_);

	registeredModule_.timeout = setTimeout(() => {
		if (registeredModule_.item && !registeredModule_.item.getAttribute('mouse-over')) {
			__.loopAutoAnimation(registeredModule_);
		}
	}, registeredModule_.options.animationTime);
};

__.onTimeOutAnimation = function (module_) {
	return function() {
		__.loopAutoAnimation(module_);
	};
};

__.setTimeoutForAnimation = function(registeredModule_) {
	if (registeredModule_.options.autoAnimation) {
		if (registeredModule_.timeout) {
			clearTimeout(registeredModule_.timeout);
		}

		registeredModule_.timeout = setTimeout(__.onTimeOutAnimation(registeredModule_), registeredModule_.options.animationTime);
	}
};

/**
 * starts automatically the slider animation depending on animationTime
 */
__.startAutoAnimation = function () {
	var i, registeredModule_,
		countItems = 0
		;

	for (i = 0; i < __.registeredItems.length; i++) {
		registeredModule_ = __.registeredItems[i];
		countItems = dom.getElementsArray(registeredModule_.options.selectorInputs, registeredModule_.item).length;

		if (countItems > 1) {
			__.setTimeoutForAnimation(registeredModule_);
		}
	}
};

/**
 * adds click and mouse events to the body
 */
__.addEvents = function () {
	var i, delegate;

	for (i = 0; i < __.registered.length; i++) {
		if (!__.registered[i].eventsAdded) {
			delegate = dom.getEventDelegate('body');
			delegate.on(
				'click',
				__.registered[i].selector + ' ' + __.registered[i].options.selectorSliderLeft,
				__.onPrevClick(__.registered[i])
			);
			delegate.on(
				'click',
				__.registered[i].selector + ' ' + __.registered[i].options.selectorSliderRight,
				__.onNextClick(__.registered[i])
			);
			delegate.on(
				'change',
				__.registered[i].selector + ' ' + __.registered[i].options.selectorInputs,
				__.onChange
			);

			if (__.registered[i].options.pauseAnimation) {
				delegate.on(
					'mouseover',
					__.registered[i].selector,
					__.onMouseOver(__.registered[i])
				);
				delegate.on(
					'mouseout',
					__.registered[i].selector,
					__.onMouseOut(__.registered[i])
				);
			}

			__.registered[i].eventsAdded = true;
		}
	}
};

/**
 * sets the current label as checked
 */
__.setSelectedLabels = function () {
	var i, k,
		inputs_,
		labels_,
		item_
		;

	for (i = 0; i < __.registeredItems.length; i++) {
		item_ = __.registeredItems[i].item;
		inputs_ = dom.getElementsArray(__.registeredItems[i].options.selectorInputs, item_);
		labels_ = dom.getElementsArray(__.registeredItems[i].options.selectorLabels, item_);

		for (k = 0; k < inputs_.length; k++) {
			labels_[k].classList.remove('selected');

			if (inputs_[k].checked) {
				labels_[k].classList.add('selected');
			}
		}
	}
};

/**
 * sets the active slide
 */
__.setActiveSlide = function () {
	var i,
		inputs_,
		prevArrow_,
		nextArrow_,
		activeSlide_,
		item_
		;

	for (i = 0; i < __.registeredItems.length; i++) {
		item_ = __.registeredItems[i].item;
		inputs_ = dom.getElementsArray(__.registeredItems[i].options.selectorInputs, item_);
		prevArrow_ = dom.getElement(__.registeredItems[i].options.selectorSliderLeft, item_);
		nextArrow_ = dom.getElement(__.registeredItems[i].options.selectorSliderRight, item_);

		__.resetArrowsState(
			item_,
			__.registeredItems[i].options.selectorSliderLeft,
			__.registeredItems[i].options.selectorSliderRight
		);

		activeSlide_ = __.getActiveSlide(inputs_);

		if (activeSlide_ === 0 && !__.registeredItems[i].options.alwaysShowArrows) {
			prevArrow_.classList.add('inactive');
		}
		else if (activeSlide_ === inputs_.length-1 && !__.registeredItems[i].options.alwaysShowArrows) {
			nextArrow_.classList.add('inactive');
		}
	}
};

/**
 * returns the active slide
 *
 * @param {Object} inputs_ - registered item options
 * @returns {number} current active slide
 */
__.getActiveSlide = function(inputs_) {
	var k, activeSlide_;

	for (k = 0; k < inputs_.length; k++) {
		if (inputs_[k].checked) {
			activeSlide_ = k;
		}
	}

	return activeSlide_;
};

/**
 * reset the arrow state
 * @param {Object} item_ - the current item
 * @param {Object} selectorLeft_ - left arrow selector
 * @param {Object} selectorRight_ - right arrow selector
 */
__.resetArrowsState = function(item_, selectorLeft_, selectorRight_) {
	var prevArrow_, nextArrow_;

	prevArrow_ = dom.getElement(selectorLeft_, item_);
	nextArrow_ = dom.getElement(selectorRight_, item_);

	prevArrow_.classList.remove('inactive');
	nextArrow_.classList.remove('inactive');
};

/**
 * checks the default options and overrides them with registered item options
 *
 * @param {Object} options_ - registered item options
 * @returns {Object} options
 */
__.checkOptions = function (options_) {
	var sliderOptions = __.cloneObject(options),
		ret = __.cloneObject(sliderOptions),
		key;

	for (key in options_) {
		if (Object.prototype.hasOwnProperty.call(options_, key)) {
			ret[key] = options_[key];
		}
	}

	return ret;
};

/**
 * get the current registered item
 *
 * @param {Object} item_ - slider item
 * @returns {Object} registered items
 */
__.getRegisteredItem = function(item_) {
	var i,
		ret;

	for (i = 0; i < __.registeredItems.length; i++) {
		if (__.registeredItems[i].item === item_) {
			ret = __.registeredItems[i];
		}
	}

	return ret;
};

/**
 * sets slider options from data attributes for each item
 *
 * @param {HTMLElement} item_ - slider item
 * @param {Object} registeredOptions_ - options of registeredModule
 * @returns {Object} options
 */
__.setItemOptionsFromDataAttributes = function(item_, registeredOptions_) {
	var altOptions = __.cloneObject(registeredOptions_);

	if (item_ && item_.getAttribute('data-duration')) {
		altOptions.animationTime = item_.getAttribute('data-duration');
	}

	if (item_ && item_.getAttribute('data-rotation')) {
		altOptions.autoAnimation = false;

		if (item_.getAttribute('data-rotation').toLowerCase() === 'true' || item_.getAttribute('data-rotation') === true) {
			altOptions.autoAnimation = true;
		}
	}

	return altOptions;
};

/**
 * saves custom options for each slider item
 *
 * @param {Object} selector_ - DOM selector
 * @param {Object} options_ - slider Options
 */
__.saveRegisteredItems = function (selector_, options_) {
	var items_ = dom.getElementsArray(selector_), i;

	for (i = 0; i < items_.length; i++) {
		__.registeredItems.push({
			item : items_[i],
			options : __.setItemOptionsFromDataAttributes(items_[i], options_),
			selector : selector_
		});
	}
};

/**
 * cloneObject
 * @param {Object} obj - Number
 * @returns {Object} returns Object
 */
__.cloneObject = function(obj) {
	var temp, key;

	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	temp = obj.constructor(); // give temp the original obj's constructor

	for (key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			temp[key] = __.cloneObject(obj[key]);
		}
	}

	return temp;
};

/**
 * registered slider items with custom options
 * @param {Object} selector_ - DOM selector
 * @param {Object} options_ - slider Options
 */
__.register = function(selector_, options_) {
	var newOptions_ = __.checkOptions(options_);

	if (selector_ && selector_ !== '') {
		__.registered.push({
			selector : selector_,
			options : newOptions_,
			eventsAdded : false
		});
		__.saveRegisteredItems(selector_, newOptions_);
		__.setSelectedLabels();
		__.setActiveSlide();
		__.addEvents();
		__.startAutoAnimation();

	}
	else {
		throw new Error('slider.js --> no selector defined');
	}
};

/**
 * public initialize method
 * @returns {Promise} resolve after init
 */
api.initialize = function() {
	__.sliderEventBus = signal.getEmitter('slider');
	return Promise.resolve('slider.js');
};

api.register = __.register;

export {api as slider};
