var __, exports;

__ = {};
exports = {
	__
};
// default intervall for polling (wait) method Run all 50 ms.
__.polling = {
	defaultIntervalTime: 50
};

/**
 * deep merge objects
 * @param {Object} out - ObjectA
 * @returns {Object} returns Object
 * @example
 * extend({}, objA, objB);
 */
exports.extend = function(out) {
	var out_ = out || {},
		i, obj, key;

	for (i = 1; i < arguments.length; i++) {
		obj = arguments[i];

		if (!obj) {
			continue;
		}

		for (key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				if (typeof obj[key] === 'object') {
					exports.extend(out_[key], obj[key]);
				}
				else {
					out_[key] = obj[key];
				}
			}
		}
	}

	return out_;
};


/**
 * convertNumberFormat
 * @param {Object} number_ - Number
 * @returns {Object} returns comverted Number format
 */
exports.convertNumberFormat = function(number_) {
	var ret = parseFloat(number_),
		x, x1, x2, rgx;

	ret = ret.toFixed(2) + '';

	ret = ret.replace('.', ',');
	x = ret.split(',');
	x1 = x[0];
	x2 = x.length > 1 ? ',' + x[1] : '';
	rgx = /(\d+)(\d{3})/;

	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1.$2');
	}

	ret = x1 + x2;

	return ret;
};

export {exports as common};
