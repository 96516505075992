import {appEvents, signal} from 'application-bundle';

var __ = {},
	exports = {__};

/**
 * load js asynchronously
 * @param {string} url_ - the URL of the js source
 * @returns {Promise} - resolves when successfully loaded, rejects else
 */
exports.loadURL = function(url_) {
	var script;

	return new Promise((resolve, reject) => {
		script = document.createElement('script');
		script.type = 'text/javascript';

		script.onerror = function () {
			reject(new Error('could not load Script from ' + url_));
		};

		if (script.readyState) { // IE
			script.onreadystatechange = function () {
				if (script.readyState === 'loaded' || script.readyState === 'complete') {
					script.onreadystatechange = null;
					resolve();
				}
			};
		}
		else { // Others
			script.onload = function () {
				resolve();
			};
		}

		script.src = url_;
		document.getElementsByTagName('head')[0].appendChild(script);
	});
};

__.initialize = function() {
	// console.log('DOMReady jsloader.js -- initialize');
};

__.eventBus = signal.getEmitter();
__.eventBus.once(appEvents.APP_READY, __.initialize);

export {exports as jsloader};
