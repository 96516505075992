class MessageAPI {
	constructor() {
		this.registered = {};
		this.handleWindowMessage = this._handleWindowMessage.bind(this);
		this._initialize();
	}

	_initialize = function () {
		this._addEvents();
	};

	_addEvents = function () {
		window.addEventListener('message', this.handleWindowMessage, false);
	};

	/**
	 * triggers calling of callbacks for the registered action
	 * @param {event} event_ - MessageEvent
	 * @property {string} event_.data - message data as stringified JSON
	 */
	_handleWindowMessage = function (event_) {
		const payLoad = this._parseJsonDataFromMessageString(event_.data);

		if (!!payLoad && !!payLoad.action && this._isRegisteredAction(payLoad.action)) {
			// add origin to the payload for later referencing the source frame
			payLoad.origin = event_.origin;
			// call callback with data
			this._callAllRegisteredCallbacks(payLoad.action, payLoad);
		}
	};

	/**
	 * parse event data to JSON
	 * @param {string} eventMessage_ - stringified JSON
	 * @returns {(Object|string)} - parsed JSON or unchanged input string
	 */
	_parseJsonDataFromMessageString = function (eventMessage_) {
		try {
			return window.JSON.parse(eventMessage_);
		}
		catch (error_) {
			if (typeof eventMessage_ === 'string' || eventMessage_ instanceof Object) {
				return eventMessage_;
			}
			else {
				console.warn('could not parse json data from event:', error_);
			}
		}
	};

	/**
	 * @param {string} actionName_ - name of the action
	 * @param {Object} payLoad_ - payload of the event (data of MessageEvent)
	 */
	_callAllRegisteredCallbacks = function (actionName_, payLoad_) {
		const callBacksArray = this.registered[actionName_];

		if (!!callBacksArray && callBacksArray.length) {
			callBacksArray.forEach((cB) => {
				cB(payLoad_);
			});
		}
	};

	/**
	 * check if a callback method is assigned to this action
	 * @param {string} actionName_ - name of the action
	 * @returns {boolean} - true if there is at least one registered callback for given action, else false
	 */
	_isRegisteredAction = function (actionName_) {
		return (!!this.registered[actionName_] && this.registered[actionName_].length > 0);
	};

	/**
	 * register callback function to a specific window message action
	 * multiple callbacks can be assigned to the same message action
	 * @param {string} action_ - action name (read from event.data)
	 * @param {Function} callBack_ - callback method for given action
	 */
	registerForWindowEvent = function (action_, callBack_) {
		if (typeof callBack_ === 'function') {
			this.registered[action_] = this.registered[action_] || [];
			this.registered[action_].push(callBack_);
		}
	};

	/**
	 * get iframe with the given src url
	 * @param {string} frameSource_ - source url
	 * @returns {HTMLElement|undefined} - iframe with matching src url
	 */
	getFrameOriginByUrl = function (frameSource_) {
		return document.querySelector('iframe[src*="' + frameSource_ + '"]');
	};
}

const messageApi = new MessageAPI(); // --> always same instance of MessageApi is used!

export default messageApi;
