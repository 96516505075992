export default class OffCanvas {

	static get CLASS_PANELS() { return 'off-canvas-panel'; }
	static get CLASS_WRAPPER() { return 'off-canvas-wrapper'; }
	static get CLASS_TOGGLE() { return 'off-canvas-toggle'; }
	static get CLASS_TOGGLE_BACK() { return 'toggle-backward'; }
	static get CLASS_TOGGLE_FROM_RIGHT() { return 'off-canvas-right'; }
	static get CLASS_ACTIVE_CANVAS_COMPONENT() { return 'is-active-off-canvas-componente'; }

	/**
	* @param {Object} canvasContainerDiv_ - container div / wrapper of component
	*/
	constructor(canvasContainerDiv_) {
		this.offCanvas = canvasContainerDiv_;
		this.numberOfPanel = null;
		this.panels = [];
		this.wrapper = null;
		this.currentPanelIndex = 0;
		this.directionFactor = null;
		this.toggleEventHandler = this._handleClickOnToggle.bind(this);
		this.panelChangeEvent = new CustomEvent('offcanvas_panel_change');
		this._init();
	}

	_destroyInstance() {
		this._unbindEventsFromToggleButton();
		this._resetCalculatedStyles();
		this._deactivateComponents();
		this.offCanvas = null;
	}

	_deactivateComponents() {
		this.offCanvas.classList.remove(OffCanvas.CLASS_ACTIVE_CANVAS_COMPONENT);
		this.wrapper.classList.remove(OffCanvas.CLASS_ACTIVE_CANVAS_COMPONENT);

		for (let i = 0; i < this.panels.length; i++) {
			this.panels[i].classList.remove(OffCanvas.CLASS_ACTIVE_CANVAS_COMPONENT);
		}
	}

	_init() {
		this._initOffCanvas();
		this._initWrapper();
		this._initPanel();
		this._initToggleButtons();
	}

	_initOffCanvas() {
		if (this.offCanvas.classList.contains(OffCanvas.CLASS_TOGGLE_FROM_RIGHT)) {
			this.directionFactor = 1;
		}
		else {
			this.directionFactor = -1;
		}

		this.offCanvas.classList.add(OffCanvas.CLASS_ACTIVE_CANVAS_COMPONENT);
	}

	_initToggleButtons() {
		this.toggleButtons = this.offCanvas.querySelectorAll('.' + OffCanvas.CLASS_TOGGLE);

		if (this.toggleButtons) {
			this._bindEventsOnToggleButtons();
		}
	}

	_initPanel() {
		this.panels = this.offCanvas.querySelectorAll('.' + OffCanvas.CLASS_PANELS);

		if (this.panels) {
			this._setPositionOfPanels();
		}
	}

	_initWrapper() {
		this.wrapper = this.offCanvas.querySelector('.' + OffCanvas.CLASS_WRAPPER);

		if (this.wrapper) {
			this.wrapper.style.right = '0';
			this.wrapper.classList.add(OffCanvas.CLASS_ACTIVE_CANVAS_COMPONENT);
		}
	}

	_setPositionOfPanels() {
		const panelsLength = this.panels.length;

		for (let i = 1; i < panelsLength; i++) {
			this.panels[i].classList.add(OffCanvas.CLASS_ACTIVE_CANVAS_COMPONENT);
			this.panels[i].style.right = (this.directionFactor * -1) * (100 * i) + '%';
		}
	}

	_resetCalculatedStyles() {
		const panelsLength = this.panels.length;

		for (let i = 1; i < panelsLength; i++) {
			this.panels[i].style.right = 'auto';
		}

		this.wrapper.style.right = 'auto';
	}

	_bindEventsOnToggleButtons() {
		for (let i = 0; i < this.toggleButtons.length; i++) {
			let toggleButton = this.toggleButtons[i];

			toggleButton.addEventListener('click', this.toggleEventHandler);
		}
	}

	/**
	* @param {Event} event_ - click event
	*/
	_handleClickOnToggle(event_) {
		const button = this._getButtonFromEventTarget(event_.target);

		if (this.panels.length === 2) {
			this._toggleBetweenTwoPanels();
		}
		else {
			let direction = this._getToggleDirection(button);

			if (direction === 'forward') {
				this.toggleForward();
			}
			else {
				this.toggleBackward();
			}
		}

		this._handlePanelChange();
	}

	/**
	 * @param {HTMLElement} eventTarget_ -
	 * @returns {HTMLElement} - the closest toggle parent
	 */
	_getButtonFromEventTarget(eventTarget_) {
		if (eventTarget_.classList.contains(OffCanvas.CLASS_TOGGLE)) {
			return eventTarget_;
		}

		return eventTarget_.closest('.' + OffCanvas.CLASS_TOGGLE);
	}

	_unbindEventsFromToggleButton() {
		const toggleButtonsLength = this.toggleButtons.length;

		for (let i = 0; i < toggleButtonsLength; i++) {
			let toggleButton = this.toggleButtons[i];

			toggleButton.removeEventListener('click', this.toggleEventHandler);
		}
	}

	_toggleBetweenTwoPanels() {
		if (this.currentPanelIndex === 0) {
			this.currentPanelIndex = 1;
		}
		else {
			this.currentPanelIndex = 0;
		}

		this.wrapper.style.right = this.currentPanelIndex * 100 + '%';
	}

	_setActivePanelIndexAttribute() {
		this.wrapper.setAttribute('data-active-index', this.currentPanelIndex);
	}

	_handlePanelChange() {
		this._setActivePanelIndexAttribute();
		document.dispatchEvent(this.panelChangeEvent);
	}

	/**
	* @param {Object} button_ - button that triggers movement of canvas
	* @returns {string} direction - direction ("forward" || "backward")
	*/
	_getToggleDirection(button_) {
		let direction = 'forward';

		if (button_.classList.contains(OffCanvas.CLASS_TOGGLE_BACK)) {
			direction = 'backward';
		}

		return direction;
	}

	/* *****  PUBLIC FUNCTIONS: ****** */
	toggleForward() {
		this.currentPanelIndex++;

		if (this.currentPanelIndex >= this.panels.length) {
			this.currentPanelIndex = this.panels.length - 1;
		}

		this.wrapper.style.right = this.directionFactor * this.currentPanelIndex * 100 + '%';
	}

	toggleBackward() {
		this.currentPanelIndex--;

		if (this.currentPanelIndex < 0) {
			this.currentPanelIndex = 0;
		}

		this.wrapper.style.right = this.directionFactor * this.currentPanelIndex * 100 + '%';
	}

	toggleToLastPanel() {
		this.currentPanelIndex = this.panels.length - 1;
		this.wrapper.style.right = this.currentPanelIndex * 100 + '%';
		this._handlePanelChange();
	}

	toggleToFirstPanel() {
		this.currentPanelIndex = 0;
		this.wrapper.style.right = '0%';
	}
}

export {OffCanvas};
