import {common} from './common';
import {signal} from 'application-bundle';

var __, exports;

__ = {};
exports = {
	__
};
__.oDefaults = {
	selectorSlideItem: '.axs-at-slide',
	selectorSliderLeft: '.axs-at-slider-arrow-left',
	selectorSliderRight: '.axs-at-slider-arrow-right',
	selectorInputs: '.axs-at-slider-input',
	selectorLabels: '.axs-at-slider-input-label',
	selectorTransition: 'axs-slider-no-transition',
	autoAnimation: false,
	animationTime: 3000,
	pauseAnimation: false,
	alwaysShowArrows: false,
	animateCarousel: false
};
__.isAnimating = false;
__.instances = [];

/**
 * Constructor function
 * @param {HTMLElement} contextElement_ - context Element for the carousel
 * @param {Object} options_ - custom options for this carousel instance
 * @returns {Carousel} Carousel instance
 */
__.Carousel = function (contextElement_, options_) { // eslint-disable-line max-statements
	if (!contextElement_ || !contextElement_.querySelectorAll) {
		console.warn('could not instanciate Carousel');
		return null;
	}

	this.context = contextElement_;
	// merge custom options into options
	this.options = common.extend({}, __.oDefaults, options_);
	this.isAnimating = false;
	this.loopPaused = false;
	this.animationTimeOutID = 0;
	this.inputsArr = [].slice.call(this.context.querySelectorAll(this.options.selectorInputs));
	this.labelsArr = [].slice.call(this.context.querySelectorAll(this.options.selectorLabels));
	this.slidesArr = [].slice.call(this.context.querySelectorAll(this.options.selectorSlideItem));
	this.maxIndex = this.inputsArr.length - 1;
	this.selectedIndex = __.getCurrentCheckedIndex(this.inputsArr);
	// parse additional data from data attributes
	this.parseOptionsDataAttributes();
	// add Events
	__.addCarouselEvents(this);
	// start animation carousel (if active)
	this.resetArrowsState();
	this.setArrowsStyle(this.context);
	this.startAnimationLoop();
	return this;
};

/**
 * get checked item's index from Array of HTMLElements
 * @param {Array} itemsArr - array of HTML ELements
 * @returns {number} index of the checked item in the list
 */
__.getCurrentCheckedIndex = function (itemsArr) {
	var i = 0,
		len = itemsArr.length;

	for (i = 0; i < len; i++) {
		if (itemsArr[i].checked) {
			return i;
		}
	}

	return 0;
};

/**
 * show/animate to previous slide
 */
__.Carousel.prototype.previous = function () {
	var newIndex = this.getPreviousIndex();

	this.slideTo(newIndex);
};

/**
 * show/animate to next slide
 */
__.Carousel.prototype.next = function () {
	var newIndex = this.getNextIndex();

	this.slideTo(newIndex);
};

/**
 * activate css Transition to slide a index
 * waits for transition end and sets next input's state to checked
 * starts endless loop is boundaries (index=0 or index=max) is reached
 * @param {number} index_ - index to be animated to
 */
__.Carousel.prototype.activateTransition = function (index_) {
	var that = this;
	var inputs = that.inputsArr || [];

	that.isAnimating = true;
	inputs[index_].checked = 'checked';
	__.waitForTransitionEnd(that.context).then(() => {
		return that.checkBoundaries();
	}).catch((err) => {
		console.error(err);
	}).then(() => {
		that.setSelectedLabels(that.selectedIndex);
		that.setArrowsStyle(that.context, that.selectedIndex);
		that.isAnimating = false;
	});
};

/**
 * @param {HTMLElement} element_ - DOM Element to watch transition End
 * @returns {Promise} tranistion end
 */
__.waitForTransitionEnd = function (element_) {
	return new Promise((resolve, reject) => {
		if (!element_) {
			reject('element missing');
		}

		element_.addEventListener('transitionend', function _transitionHandler(event_) {
			element_.removeEventListener('transitionend', _transitionHandler);
			resolve(event_);
		}, false);
	});
};

/**
 * handle change in input/dot clicks
 * @param {Event} event_ - change event
 */
__.Carousel.prototype.handleInputChanges = function (event_) {
	var that = this;

	that.isAnimating = true;
	that.startAnimationLoop();
	__.waitForTransitionEnd(that.context).then(() => {
		that.inputsArr.forEach((el, index_) => {
			if (el === event_.target) {
				that.selectedIndex = index_;
				return;
			}
		});
	}).catch((err) => {
		console.error(err);
	}).then(() => {
		// set label's selected state
		that.setSelectedLabels(that.selectedIndex);
		that.isAnimating = false;
	});
};

/**
 * select active input's label
 * @param {number} index_ - selected index (optional)
 */
__.Carousel.prototype.setSelectedLabels = function (index_) {
	var index = index_ - 1 || (__.getCurrentCheckedIndex(this.inputsArr) - 1);

	this.labelsArr.forEach((el) => {
		el.classList.remove('selected');
	});
	this.labelsArr[index].classList.add('selected');
};

/**
 * set arrows style (black/white)
 * @param {HTMLElement} context_ - selected index (optional)
 * @param {number} index_ - selected index (optional)
 */
__.Carousel.prototype.setArrowsStyle = function (context_, index_) {
	var index = index_ ? index_ : 1,
		style = '',
		left,
		right;

	this.slidesArr.forEach((el, i) => {
		if (index === i) {
			style = el.getAttribute('data-style');
		}
	});

	left = context_.querySelector(__.oDefaults.selectorSliderLeft);

	if (!!left) {
		left.className = __.oDefaults.selectorSliderLeft.replace('.', '');

		if (left.classList.add.length > 0) {
			left.classList.add(style);
		}
	}

	right = context_.querySelector(__.oDefaults.selectorSliderRight);

	if (!!right) {
		right.className = __.oDefaults.selectorSliderRight.replace('.', '');

		if (right.classList.add.length > 0) {
			right.classList.add(style);
		}
	}

};

/**
 * parse additional option params from DOM
 */
__.Carousel.prototype.parseOptionsDataAttributes = function () {
	var autoAnimation = this.context.getAttribute('data-rotation'),
		duration = this.context.getAttribute('data-duration');

	if (!!duration) {
		this.options.duration = parseInt(duration, 10);
	}

	if (!!autoAnimation) {
		this.options.autoAnimation = (autoAnimation.toString().toLowerCase() === 'true');
	}
};

/**
 * start transition if no current transition is running ('isAnimating')
 * @param {number} index_ - index to be slided to
 */
__.Carousel.prototype.slideTo = function (index_) {
	// console.log("slideTo", index_);
	if (this.isAnimating) {
		console.warn('Animation is still running…');
	}
	else {
		this.activateTransition(index_);
	}
};

/**
 * calculate next index and set selectedIndex (endless loop)
 */
__.Carousel.prototype.getNextIndex = function () {
	this.selectedIndex++;

	if (this.selectedIndex > this.maxIndex) {
		this.selectedIndex = 0;
	}

	return this.selectedIndex;
};

/**
 * calculate previous index and set selectedIndex (endless loop)
 */
__.Carousel.prototype.getPreviousIndex = function () {
	this.selectedIndex--;

	if (this.selectedIndex < 0) {
		this.selectedIndex = this.maxIndex;
	}

	return this.selectedIndex;
};

/**
 * handle back arrow clicks
 * @param {Event} event_ - DomEvent
 */
__.Carousel.prototype.handlePrevClick = function (event_) {
	event_.preventDefault();
	this.startAnimationLoop();
	this.previous();
};

/**
 * handle next arrow clicks
 * @param {Event} event_ - DomEvent
 */
__.Carousel.prototype.handleNextClick = function (event_) {
	event_.preventDefault();
	this.startAnimationLoop();
	this.next();
};

/**
 * pause AnimationLoop during mouseover
 * @param {Event} event_ - DomEvent
 */
__.Carousel.prototype.onMouseOver = function (event_) {
	this.loopPaused = true;
	event_.preventDefault();
};

/**
 * restart AnimationLoop on mouseout
 * @param {Event} event_ - DomEvent
 */
__.Carousel.prototype.onMouseOut = function (event_) {
	event_.preventDefault();
	this.loopPaused = false;
};

/**
 * check boundaries (n,0,1,2,3,4,n,0)
 * moves slides to the end/start of boundaries are reached
 * @returns {Promise} Promise
 */
__.Carousel.prototype.checkBoundaries = function () {
	var promise = Promise.resolve(),
		that = this;

	if (that.selectedIndex === 0) {
		// console.log("first");
		promise.then(() => {
			return that.fixCheckedPosition(that.maxIndex - 1);
		});
	}
	else if (that.selectedIndex === that.maxIndex) {
		// console.log("last");
		promise.then(() => {
			return that.fixCheckedPosition(1);
		});
	}

	this.selectedIndex = Math.min(this.selectedIndex, that.maxIndex);
	// console.log("this.selectedIndex", this.selectedIndex);
	return promise;
};

/**
 * disables CSS transition during position correctios
 * while correction the checked index
 * @param {number} newIndex_ - new index to set checked state
 * @returns {Promise} Promise
 */
__.Carousel.prototype.fixCheckedPosition = function (newIndex_) {
	var that = this, inputs;

	return new Promise(resolve => {
		that.disableAndEnableTransition(true);
		inputs = that.inputsArr || [];
		// uncheck all inputs
		inputs.forEach((el, index) => {
			// set new input's state to checked while CSS transtion is disabled
			if (index === newIndex_) {
				el.checked = true;
			}
			else {
				el.checked = false;
			}
		});
		that.selectedIndex = newIndex_;
		// enable CSS transition
		setTimeout(() => {
			that.disableAndEnableTransition(false);
			that.isAnimating = false;
			resolve();
		}, 50);
	});
};

/**
 * disable or enable css tranbsition
 * @param {boolean} disableTransition_ - flag to diable transition
 */
__.Carousel.prototype.disableAndEnableTransition = function (disableTransition_) {
	var that = this;

	[].forEach.call(that.context.querySelectorAll('.axs-slider-content ul'), (el) => {
		if (disableTransition_) {
			el.classList.add(that.options.selectorTransition);
		}
		else {
			el.classList.remove(that.options.selectorTransition);
		}
	});
};

/**
 * add Events to Carousel
 * @param {Carousel} carouselInstance_ - carousel instance
 */
__.addCarouselEvents = function (carouselInstance_) {
	var arrowLeft, arrowRight;

	arrowLeft = carouselInstance_.context.querySelector(carouselInstance_.options.selectorSliderLeft);

	if (arrowLeft) {
		arrowLeft.addEventListener('click', carouselInstance_.handlePrevClick.bind(carouselInstance_));
	}

	arrowRight = carouselInstance_.context.querySelector(carouselInstance_.options.selectorSliderRight);

	if (arrowRight) {
		arrowRight.addEventListener('click', carouselInstance_.handleNextClick.bind(carouselInstance_));
	}

	[].forEach.call(carouselInstance_.context.querySelectorAll('.axs-at-slider-input'), (el) => {
		el.addEventListener('change', carouselInstance_.handleInputChanges.bind(carouselInstance_));
	});
	// disable image dragging
	[].forEach.call(carouselInstance_.context.querySelectorAll('img'), (el) => {
		el.draggable = false;
	});

	// DOM_UTILS.getEventDelegate('body').on('change', '.axs-at-slider-input', carouselInstance_.handleInputChanges.bind(carouselInstance_));
	if (carouselInstance_.options.pauseAnimation && carouselInstance_.labelsArr.length > 1) {
		carouselInstance_.context.addEventListener('mouseover', carouselInstance_.onMouseOver.bind(carouselInstance_));
		carouselInstance_.context.addEventListener('mouseout', carouselInstance_.onMouseOut.bind(carouselInstance_));
	}
};

/**
 * stop/clear animation loop interval
 */
__.Carousel.prototype.stopAnimationLoop = function () {
	if (this.animationTimeOutID) {
		clearInterval(this.animationTimeOutID);
		this.animationTimeOutID = null;
	}
};

/**
 * start animation loop interval
 */
__.Carousel.prototype.startAnimationLoop = function () {
	var that = this,
		animationTime = this.options.duration ? this.options.duration : that.options.animationTime;

	this.stopAnimationLoop();

	// console.log("startAnimationLoop…", this.options.duration);
	if (this.options.autoAnimation && this.labelsArr.length > 1) {
		// console.log("animationLoop started…");
		this.animationTimeOutID = setInterval(() => {
			if (that.loopPaused) {
				return;
			}

			that.next();
		}, animationTime);
	}
};

/**
 * resetArrowsState (show or hide next/back buttons)
 */
__.Carousel.prototype.resetArrowsState = function () {
	var prevArrow_ = this.context.querySelector(this.options.selectorSliderLeft),
		nextArrow_ = this.context.querySelector(this.options.selectorSliderRight);

	if (prevArrow_ && nextArrow_) {
		if (this.options.alwaysShowArrows) {
			prevArrow_.classList.remove('inactive');
			nextArrow_.classList.remove('inactive');
		}
		else {
			prevArrow_.classList.add('inactive');
			nextArrow_.classList.add('inactive');
		}
	}
};

/**
 * public initialize method
 * @returns {Promise} resolves if initialized
 */
exports.initialize = function () {
	return new Promise(resolve => {
		__.sliderEventBus = signal.getEmitter('carousel');
		resolve('carousel.js');
	});
};

/**
 * create and return new Carousel instance
 * @param {HTMLElement} contextElement_ - context element
 * @param {Object} options_ - custom options (optional)
 * @returns {Carousel} Carousel Instance
 */
exports.getCarouselInstance = function (contextElement_, options_) {
	var instance = new __.Carousel(contextElement_, options_);

	__.instances.push(instance);
	return instance;
};

export {exports as carousel};
