export default class DisableContextHelper {
	constructor(context_) {
		this.context_ = context_;
		this.nodes = [];
		this.originalTabindices = [];

		this._initialize();
	}

	_initialize() {
		this._initChildNodesArray();
	}

	_initChildNodesArray() {
		const walker = document.createTreeWalker(this.context_, NodeFilter.SHOW_ELEMENT, null, false);

		while (walker.nextNode()) {
			this.nodes.push(walker.currentNode);
		}
	}

	/**
	 * make each child element not tabable by setting tabindex to '-1'
	 */
	disableContext() {
		const nodeLength = this.nodes.length;

		for (let i = 0; i < nodeLength; i++) {
			const element = this.nodes[i];

			this._storeOriginalTabindexForElement(element);
			this._setDisableTabindexForElement(element);
		}
	}

	/**
	 * @param {HTMLElement} element_ - element, that tabindex should be stored for
	 */
	_storeOriginalTabindexForElement(element_) {
		const tabindexOfElement = this._getTabindexValueOfElement(element_);

		this.originalTabindices.push(tabindexOfElement);
	}

	/**
	 * @param {HTMLElement} element_ - element, that tabindex should be manipulated
	 */
	_setDisableTabindexForElement(element_) {
		element_.setAttribute('tabindex', '-1');
	}

	/**
	 * @param {HTMLElement} element_ - element
	 * @returns {number} - tabindex value
	*/
	_getTabindexValueOfElement(element_) {
		// Edge 14 has a capitalization problem on SVG elements
		let tabindex = null;
		const hasTabindex = element_.hasAttribute('tabindex');
		const attributeName = hasTabindex ? 'tabindex' : 'tabIndex';
		const tabIndexValue = element_.getAttribute(attributeName);

		if (tabIndexValue) {
			tabindex = parseInt(tabIndexValue, 10);
		}

		return tabindex;
	}

	/**
	 * restore original tabindex
	 */
	enableContext() {
		const nodeLength = this.nodes.length;

		for (let i = 0; i < nodeLength; i++) {
			const element = this.nodes[i];
			const originalTabindex = this.originalTabindices[i];

			if (originalTabindex !== null) {
				element.setAttribute('tabindex', originalTabindex);
			}
			else {
				element.removeAttribute('tabindex');
			}
		}
	}
}

export {DisableContextHelper};
