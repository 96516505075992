import {signal} from 'application-bundle';
import {DisableContextHelper} from './global/accessibility/disable-context';
import {carousel} from './global/carousel';
import {common} from './global/common';
import {JClickable} from './global/clickable';
import {JScrollReveal} from './global/scroll-reveal';
import {jsloader} from './global/jsloader';
import messageApi from './global/message-api';
import {OffCanvas} from './global/off-canvas';
import {scroll} from './global/scroll';
import {slider} from './global/slider';

/**
 * modules to be initialized with event emitter
 */
const toBeInitialized = [
	carousel,
	scroll
];

/**
 * initialize modules and inject signal dependency
 */
const eventEmitter = signal.getEmitter();

toBeInitialized.forEach(module => {
	try {
		module.initialize(eventEmitter);
	}
	catch (err) {
		console.error(err);
	}
});

export {
	carousel,
	common,
	DisableContextHelper,
	JClickable,
	JScrollReveal,
	jsloader,
	OffCanvas,
	messageApi,
	scroll,
	slider
};
